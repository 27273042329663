import React, { ChangeEvent } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link, Container, Stack, Paper, Grid, TextField, Button, Typography, InputAdornment} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import logoIcon from '../images/logo.svg';
import { AppContext } from '../AppContext';
import { valueOf, stringValue } from '../common/data';
import { ErrorType } from '../common/types';
import { signIn, SignInData } from '../service/api';
import { missing } from '../common/formats';
import { validEmail } from '../common/validate';
import { Logo } from '../components/Logo';



interface SignInState {
    email?: string;
    password?: string;
    errors: ErrorType;
}

class SignIn extends React.Component<WithTranslation, SignInState> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: WithTranslation) {
        super(props);

        this.state = {
            errors: {}
        };
    }

    private validateData() {
        const app = this.context;
        const { t } = this.props;

        let result = true;
        const errors: ErrorType = {};

        if (!this.state.email) {
            errors["Email"] = true;
        }

        if (Object.keys(errors).length > 0) {
            const message = missing(Object.keys(errors), t);

            app.showMessage({
                severity: "error",
                message: message
            });

            result = false;
        }
        else {
            if (!validEmail(this.state.email)) {
                errors["Email"] = true;

                app.showMessage({
                    severity: "error",
                    message: t("Invalid Email")
                });

                result = false;
            }
        }

        this.setState({
            errors: errors
        });

        return result;
    }

    private saveData(): SignInData {
        const app = this.context;

        const data: SignInData = {
            lang: app.lang
        };

        data["email"] = this.state.email;
        data["password"] = this.state.password;

        return data;
    }

    private handleSignIn = () => {
        const app = this.context;

        if (this.validateData()) {
            const data = this.saveData();

            app.showProgress(true, () => {
                signIn(data).then((result) => {
                    if (result.url) {
                        const url = result.url;

                        app.showProgress (false, () => app.handleNavigate(url));
                    }
                    else {
                        app.setState ({
                            progress: false,
                            user_id: result.user_id,
                            user_name: result.user_name,
                            user_email: result.user_email,
                            user_picture: result.media_id ? `/api/1/umedia?id=${result.media_id}` : '/images/user.svg',
                            role: result.role
                        }, () => {
                            app.handleNavigate(`/home`);
                        });
                    }
                }).catch((err) => {
                    app.showMessage({
                        severity: "error",
                        message: err.message,
                        progress: false
                    });

                    const errors: ErrorType = {};

                    if (err.refs && err.refs.length > 0) {
                        err.refs.forEach((ref: string) => {
                            errors[ref] = true;
                        });
                    }
                    this.setState({
                        errors: errors
                    });
                });
            });
        }
    };

    handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            email: stringValue(event.target.value)
        });
    };

    handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: stringValue(event.target.value)
        });
    };

    public render() {
        const app = this.context;
        const { t } = this.props;

        return (
            <Container sx={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <Stack direction="column" sx={{ display: 'inline-flex' }}>
                    <Paper elevation={3} sx={{ marginTop: '100px', marginBottom: '10px', padding: '20px', width: '450px', borderRadius: '10px' }}>
                        <Grid container spacing={3} direction="column"
                            justifyContent="center">
                            <Grid item>
                                <Logo src={logoIcon} sx={{width:"138px"}}/>
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="sign-in-email"
                                    label={t("Email")}
                                    fullWidth
                                    value={valueOf(this.state.email)}
                                    onChange={this.handleEmailChange}
                                    error={this.state.errors["Email"] ? true : false}
                                    inputProps={{ maxLength: 128 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item>
                                <TextField
                                    id="sign-in-password"
                                    label={t("Password")}
                                    type="password"
                                    fullWidth
                                    value={valueOf(this.state.password)}
                                    onChange={this.handlePasswordChange}
                                    error={this.state.errors["Password"] ? true : false}
                                    inputProps={{ maxLength: 128 }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        )
                                    }} />
                            </Grid>
                            <Grid item sx={{ textAlign: 'right' }}>
                                <Button color="primary" variant="outlined" onClick={() => this.handleSignIn()}>{t("Sign In")}</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography sx={{ textAlign: 'right' }}><Link onClick={() => app.handleNavigate("/forgot")}  sx={{cursor:"pointer"}}> {t("Forgot your password ?")}</Link></Typography>
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        );
    }
}

export default withTranslation()(SignIn);