import { AlertColor } from "@mui/material";
import React from "react";
import {FinancialYearData, Organization} from './service/api'
export interface AppContextState {
    lang:string;
    user_id?:number;
    user_name?:string;
    user_email?:string;
    user_picture?:string;
    organization_id?:number;
    organization_name?: string;
    organizations?: Organization[];
    client?: string;
    role?: string;
    currency_code?:string;
    currency_symbol?:string;
    fraction_digits?:number;
    gl_account_pattern?:string;
    costcenter_pattern?: string;
    financial_years?: FinancialYearData[],
    financial_year_id?: number;
    page_size?:string;
    page_width?:number;
    page_height?:number;
    paper_size?: number[]|string;
    show?:boolean;
    severity?:AlertColor;
    message?:string;
    open:boolean;
    pin:boolean;
    isHelpOpen?:boolean;
    toggleSearch:boolean;
    progress?: boolean;
    search:string;
    drawer:string;
    help?:JSX.Element;
    routes?: {[key:string] : any};
    handleHome?: () => void;
    handleNavigate: (path:string, options?:any) => void;
    handleHelpDrawer: (open:boolean, help?:JSX.Element, fn?: () => void) => void;
    handleSignOut: () => void;
    handleMessageClose?: () => void;
    showMessage: (msg:any, fn?: ()=> void) => void;
    setState: <K extends keyof AppContextState>(state: ((prevState: Readonly<AppContextState>, props: Readonly<any>) => (Pick<AppContextState, K> | AppContextState | null)) | (Pick<AppContextState, K> | AppContextState | null), callback?: () => void) => void;
    showProgress: (show:boolean, fn?: () => void) => void;
    handleSearch: (search:string, callback?: () => void) => void;
    handleToggleSearch: (search?:string, callback?: () => void) => void;
    handleLanguage: (lang:string, fn?:() => void) => void;
    handleClient: (client:string, fn?:() => void) => void;
    saveState: <K>(pathname:string, state:K, fn?:() => void ) => void;
    findState: <K>(pathname:string) => K;
}

export const AppContext = React.createContext<AppContextState> ({
    lang: 'en',
    client: 'ADC',
    role: 'user',
    paper_size: [0, 0],
    toggleSearch: false,
    search:'',
    open:false,
    pin: false,
    drawer:'General Ledger',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleNavigate: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleHelpDrawer: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showMessage: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setState: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    showProgress: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSearch: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleToggleSearch: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleLanguage: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClient: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    saveState: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    findState: ():any => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSignOut: ():any => {},
    
});
